import React from "react";
import {Button, Popover, Tag, Typography} from "antd";
import {closeable, getTitle} from "./AlarmEvent";
import {QuestionCircleOutlined} from "@ant-design/icons";
import ViewAdapt2 from "./ViewAdapt2";
import LeadCompat from "../../../../leadCompat";

/**
 * @name: MobileConstantSetting
 * @author: Seeker
 * @date: 2022-03-10 15:16
 * @description：MobileConstantSetting
 * @update: 2022-03-10 15:16
 */

const label = (fontSize,value)=>{
    return <label style={{fontSize:`${fontSize}px`}}>{value}</label>
};

const TextSize = {
    min:12,
    max:80
};

const labelPopover = (fontSize,value,content)=>{
    return <div>
        <label style={{fontSize:`${fontSize}px`}}>{value}</label>
        <Popover content={content} trigger={'click'}>
            <Button style={{border: 'none', backgroundColor: 'transparent'}} size={'small'} icon={<QuestionCircleOutlined/>}/>
        </Popover>
    </div>
};

const tagRender = (props) => {
    const { value, onClose } = props;
    const onPreventMouseDown = event => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color={'#1890FF'}
            onMouseDown={onPreventMouseDown}
            visible={true}
            closable={closeable(value)}
            onClose={onClose}
            style={{ margin:'2px 4px 2px 0',padding:'1px 5px 1px 5px',borderRadius:'2px',border:'1px solid #f0f0f0'}}
        >
            {getTitle(value)}
        </Tag>
    );
}

const tagRender2 = (props) => {
    const { value, onClose } = props;
    const onPreventMouseDown = event => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color={'#1890FF'}
            onMouseDown={onPreventMouseDown}
            visible={true}
            closable={closeable(value)}
            onClose={onClose}
            style={{ margin:'2px 4px 2px 0',padding:'1px 5px 1px 5px',borderRadius:'2px',border:'1px solid #f0f0f0'}}
        >
            {LeadCompat.getLeadTitle(value)}
        </Tag>
    );
}

const treeTagRender = (props) => {
    const { label, value, onClose } = props;
    const onPreventMouseDown = event => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color={'#1890FF'}
            onMouseDown={onPreventMouseDown}
            closable={closeable(value)}
            onClose={onClose}
            visible={true}
            style={{ margin:'2px 4px 2px 0',padding:'1px 5px 1px 5px',borderRadius:'2px',border:'1px solid #f0f0f0'}}
        >
            {label}
        </Tag>
    );
}

const defaultSliderMarks = {
    40: {
        label: <Typography.Text>40</Typography.Text>
    },
    70: {
        label: <Typography.Text>70</Typography.Text>
    },
    100: {
        label: <Typography.Text>100</Typography.Text>
    },
    120: {
        label: <Typography.Text>120</Typography.Text>
    },
    160: {
        label: <Typography.Text>160</Typography.Text>
    },
};

const createSliderMarks = (min,max) =>{
    const marks = {};
    marks[ViewAdapt2.BPM_LIMIT.min] = {
        label: <Typography.Text>{ViewAdapt2.BPM_LIMIT.min}</Typography.Text>
    };
    if (min){
        marks[min] = {
            label: <Typography.Text>{min}</Typography.Text>
        };
    }
    if (max){
        marks[max] = {
            label: <Typography.Text>{max}</Typography.Text>
        };
    }
    marks[ViewAdapt2.BPM_LIMIT.max] = {
        label: <Typography.Text>{ViewAdapt2.BPM_LIMIT.max}</Typography.Text>
    };
    return marks;
};

const createTextFontSizeMarks = (value) =>{
    const {min,max} = ConstantSetting.TextSize;
    const marks = {};
    marks[min] = {
        label: <Typography.Text>{min}</Typography.Text>
    };
    if (value > min && value < max){
        marks[value] = {
            label: <Typography.Text>{value}</Typography.Text>
        };
    }
    marks[max] = {
        label: <Typography.Text>{max}</Typography.Text>
    };
    return marks;
};


const formItemLayout1 = {
    colon:false,
    labelAlign:'left',
    labelCol: {
        span: 10
    },
    wrapperCol: {
        span: 14
    },
};
const formItemLayout2 = {
    colon:false,
    labelCol: {
        span: 20
    },
    wrapperCol: {
        span: 4
    },
};


const selectFormItemLayout = {
    labelAlign:'left',
    colon:false,
    labelCol: {
        span: 5
    },
    wrapperCol: {
        span: 19
    },
};

const selectFormItemLayout2_left = {
    labelAlign:'left',
    colon:false,
    labelCol: {
        span: 10
    },
    wrapperCol: {
        span: 14
    },
};

const selectFormItemLayout2 = {
    colon:false,
    labelCol: {
        span: 13
    },
    wrapperCol: {
        span: 11
    },
};

const selectFormItemLayout3 = {
    labelAlign:'left',
    colon:false,
    labelCol: {
        span: 7
    },
    wrapperCol: {
        span: 17
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        span: 24,
    },
};

const sizeType = (clientWidth) =>{
    return clientWidth <= 1700 ? 'small' : 'default';
};

const labelFontSize = (clientWidth) =>{
    return clientWidth <= 1700 ? 12 : 14;
};

const formatDeptName = (mngDept,list) =>{
    let deptName = [];
    if (list.length) {
        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            for (let j = 0; j < mngDept.length; j++) {
                if (item === mngDept[j].deptId) {
                    deptName.push(mngDept[j].deptName);
                    break;
                }
            }
        }
    }
    return deptName;
};

const sliderMarks0 = (bpmRanger) =>{
    return ConstantSetting.sliderMarks({
        min: bpmRanger[0],
        max: bpmRanger[1]
    });
};

const sliderMarks = (hrRanger) =>{
    const {min, max} = hrRanger;
    let sliderMarks;
    if (min === max) {
        if (min === ViewAdapt2.BPM_LIMIT.min || min === ViewAdapt2.BPM_LIMIT.max) {
            sliderMarks = ConstantSetting.defaultSliderMarks;
        } else {
            sliderMarks = ConstantSetting.createSliderMarks(min);
        }
    } else {
        if (min === ViewAdapt2.BPM_LIMIT.min) {
            if (max === ViewAdapt2.BPM_LIMIT.max) {
                sliderMarks = ConstantSetting.defaultSliderMarks;
            } else {
                sliderMarks = ConstantSetting.createSliderMarks(undefined, max);
            }
        } else {
            if (max === ViewAdapt2.BPM_LIMIT.max) {
                sliderMarks = ConstantSetting.createSliderMarks(min);
            } else {
                sliderMarks = ConstantSetting.createSliderMarks(min, max);
            }
        }
    }
    return sliderMarks;
};

const treeData = (mngDept) =>{
    let children = [];
    if (mngDept.length) {
        mngDept.forEach((item) => {
            if (item.deptName) {
                let title = item.deptName;
                if (item.companyName && !item.deptName.includes(item.companyName)){
                    title = `${item.companyName}${item.deptName}`;
                }
                children.push({value: item.deptId, title: title, key: item.deptId});
            }
        })
    }
    return children;
};

const filter = (result) =>{
   return {
       darkModule: result.darkModule,
       homeStyleCode:result.homeStyleCode,
       ratioRuleShow:result.ratioRuleShow,
       edrState:result.edrState,
       bedShowLeft:result.bedShowLeft,
       nameComplete:result.nameComplete,
       departmentShow:result.departmentShow,
       nameTextFontSize:result.nameTextFontSize,
       bpmTextFontSize:result.bpmTextFontSize,
       hrRanger:result.hrRanger,
       bedTextFontSize:result.bedTextFontSize,
       bedNumLength:result.bedNumLength,
       batteryShowRule:result.batteryShowRule,
       colCount:result.colCount,
       sortWith:result.sortWith,
       minDelay:result.minDelay,
       pagerFlipDuration:result.pagerFlipDuration,
       deptId:result.deptId,
       emergencySound: result.emergencySound,
       emergencySoundState:result.emergencySoundState,
       emergencySoundInterval:result.emergencySoundInterval,
       emergencyAlarmEvent:result.emergencyAlarmEvent,
       emergencyTextShowTimeLong:result.emergencyTextShowTimeLong,
       nonEmergencySound: result.nonEmergencySound,
       nonEmergencySoundState:result.nonEmergencySoundState,
       nonEmergencySoundInterval:result.nonEmergencySoundInterval,
       nonEmergencyAlarmEvent:result.nonEmergencyAlarmEvent,
       nonEmergencyTextShowTimeLong:result.nonEmergencyTextShowTimeLong,
       derivedLead:result.derivedLead.length > 2?result.derivedLead.slice(0,2):result.derivedLead,
       gain:result.gain,
       rrLongPause:result.rrLongPause,
       channelFix:result.channelFix,
   };
};

// const showAbnormalEventSettings = (settings) =>{
//     return <div>
//         <div style={{fontSize:18,color:'#FF2600',marginBottom:15}}>紧急异常事件:</div>
//         <div style={{fontSize:18,color:'#FF8000',marginBottom:15,marginTop:30}}>非紧急异常事件:</div>
//     </div>
// };

const ConstantSetting = {
    tailFormItemLayout,
    selectFormItemLayout,
    selectFormItemLayout2,
    selectFormItemLayout2_left,
    selectFormItemLayout3,
    formItemLayout1,
    TextSize,
    formItemLayout2,
    defaultSliderMarks,
    createTextFontSizeMarks,
    createSliderMarks,
    labelPopover,
    label,
    tagRender,
    tagRender2,
    sizeType,
    labelFontSize,
    formatDeptName,
    sliderMarks,
    treeData,
    filter,
    treeTagRender,
    sliderMarks0,
    // showAbnormalEventSettings,
};

export default ConstantSetting;
