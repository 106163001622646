import nameIcon from "../../../img/ic_name.png";
import deptIcon from "../../../img/ic_department.png";
import bedIcon from "../../../img/ic_hospitalbed.png";
import nameDarkIcon2x from "../../../img/ic_name_dark@2x.png";

import deptDarkIcon2x from "../../../img/ic_department_dark@2x.png";
// import deptIcon2x from "../../../img/ic_department@2x.png";
import bedDarkIcon2x from "../../../img/ic_hospitalbed_dark@2x.png";
// import bedIcon2x from "../../../img/ic_hospitalbed@2x.png";
import channelDisconnectDarkIcon2x from "../../../img/ic__irrnormal_dark@2x.png";
// import channelDisconnectIcon2x from "../../../img/ic__irrnormal@2x.png";
import phoneDisconnectDarkIcon2x from "../../../img/ic_phone__irrnormal_dark@2x.png";
// import phoneDisconnectIcon2x from "../../../img/ic_phone__irrnormal@2x.png";
import deviceDisconnectDarkIcon2x from "../../../img/ic_device__irrnormal_dark@2x.png";
// import deviceDisconnectIcon2x from "../../../img/ic_device__irrnormal@2x.png";
import wifiDisconnectDarkIcon2x from "../../../img/Wifi_connect_dark@2x.png";
// import wifiDisconnectIcon2x from "../../../img/ic_wifi@2x.png";
import phoneBatteryLowDarkIcon2x from "../../../img/ic_phone__normal_dark_ battery1@2x.png";
// import phoneBatteryLowIcon2x from "../../../img/ic_phone__normal_LOW_BATTERY@2x.png";
import phoneBattery30DarkIcon2x from "../../../img/ic_phone__normal_dark_30@2x.png";
// import phoneBattery30Icon2x from "../../../img/ic_phone__normal_30@2x.png";
import phoneBattery60DarkIcon2x from "../../../img/ic_phone__normal_dark_60@2x.png";
// import phoneBattery60Icon2x from "../../../img/ic_phone__normal_60@2x.png";
import phoneBattery100DarkIcon2x from "../../../img/ic_phone__normal_dark_100@2x.png";
// import phoneBattery100Icon2x from "../../../img/ic_phone__normal_100@2x.png";
import bltDisconnectDarkIcon2x from "../../../img/ic_bluetooth_dark@2x.png";
// import bltDisconnectIcon2x from "../../../img/ic_bluetooth@2x.png";
import deviceBatteryLowDarkIcon2x from "../../../img/ic_device__nol_dark_ battery@2x.png";
// import deviceBatteryLowIcon2x from "../../../img/ic_device__normal_LOW_BATTERY@2x.png";
import deviceBattery30DarkIcon2x from "../../../img/ic_device__normal_dark_30@2x.png";
// import deviceBattery30Icon2x from "../../../img/ic_device__normal_30@2x.png";
import deviceBattery60DarkIcon2x from "../../../img/ic_device__normal_dark_60@2x.png";
// import deviceBattery60Icon2x from "../../../img/ic_device__normal_60@2x.png";
import deviceBattery100DarkIcon2x from "../../../img/ic_device__normal_dark_100@2x.png";
// import deviceBattery100Icon2x from "../../../img/ic_device__normal__100@2x.png";
import venueDeviceBatteryIcon from "../../../img/venue_device_battery_icon.png";
import venuePhoneBatteryIcon from "../../../img/venue_phone_battery_icon.png";
import Channel from "../../Common/Channel";
import HomeStyle from "../../../HomeStyle";
import phoneChargeDarkIcon from "../../../img/ic_phone__charge_dark@2x.png"

/**
 * @name: ContentObj
 * @author: Seeker
 * @date: 2021-08-31 11:06
 * @description：ContentObj
 * @update: 2021-08-31 11:06
 */

const contentObj = {
    name: {
        value: null,
        src: nameIcon,
        className: ''
    },
    dept: {
        src: deptIcon,
        className: ''
    },
    bed: {
        src: bedIcon,
        value: null,
        className: ''
    },
    machSN: {
        src: null,
        value: null,
        className: null
    },
    connect: {
        src: null,
        value: null,
        className: ''
    },
    mobileBattery: {
        src: null,
        value: null,
        className: '',
        textColor:null,
    },
    bleBattery: {
        src: null,
        value: null,
        className: '',
        textColor:null,
    },
    hospitalNum:{
        src: null,
        value: null,
        className: null
    },
    finish: false,
    mqttConnect:false,
    bleConnect:false
};

const hideName = (name) =>{
    const len = name.length;
    if (len === 1){
        return name;
    }
    if (len === 2){
        return `${name.substr(0,1)} *`
    }
    if (len > 2){
        let temp = '';
        for (let i = 0;i < len-2;i++){
            temp += ' * ';
        }
        return name.substr(0,1) + temp + name.substr(-1,1);
    }
    return name;
};

const update = (item,nameAndBedLarger,darkModule,homeStyleCode,stateObj,needForceOnline,nameComplete,venueSetting) => {

    let finish = false;
    let mqttConnect = false;
    let bleConnect = false;
    let mobileBattery = null;
    let bleBattery = null;

    if (nameAndBedLarger){
        contentObj.name.className = 'channel-name-large-size';
        contentObj.bed.className = 'channel-name-large-size';
    }else {
        contentObj.name.className = 'channel-name-size';
        contentObj.bed.className = 'channel-name-size';
    }
    contentObj.dept.className = 'channel-normal-size';
    contentObj.hospitalNum.className = 'channel-normal-size';
    contentObj.machSN.className = 'channel-sn-size';
    contentObj.connect.className = 'channel-normal-size';
    contentObj.mobileBattery.className = 'channel-normal-size';
    contentObj.bleBattery.className = 'channel-normal-size';

    contentObj.name.src = HomeStyle.getNameIconSrc(item.user_gender,homeStyleCode,venueSetting);
    contentObj.name.value = item && item.user_name ? (nameComplete?item.user_name:hideName(item.user_name)) : '--';
    contentObj.dept.src = deptDarkIcon2x;
    contentObj.dept.value = item && item.dept_name ? Channel.deptName(item.dept_name) : '--';
    contentObj.bed.src = bedDarkIcon2x;
    contentObj.bed.value = item && item.bed_number ? Channel.unifySymbols(Channel.bedNumber(item.bed_number)): '--';

    contentObj.machSN.value = item && item.mach_sn ? item.mach_sn : '--';
    contentObj.hospitalNum.value = item && item.hospital_number ? item.hospital_number : null;

    contentObj.mobileBattery.textColor = HomeStyle.batteryTextColor(homeStyleCode,venueSetting);
    contentObj.bleBattery.textColor = HomeStyle.batteryTextColor(homeStyleCode,venueSetting);
    if (stateObj) {
        if (stateObj.finish === 0) {
            // TODO 显示该通道已结束
            finish = true;
            contentObj.connect.src = channelDisconnectDarkIcon2x;    //TODO 替换icon
            contentObj.connect.value = '该通道已结束';
            // contentObj.mobileBattery.src = (venueSetting && venueSetting.venueOpenState)?venuePhoneBatteryIcon:phoneDisconnectDarkIcon2x;
            contentObj.mobileBattery.src = HomeStyle.getMobileBatteryIconSrc(homeStyleCode,venueSetting,0)
            contentObj.mobileBattery.value = '--';
            // contentObj.bleBattery.src = (venueSetting && venueSetting.venueOpenState)?venueDeviceBatteryIcon:deviceDisconnectDarkIcon2x;  //TODO 替换icon
            contentObj.bleBattery.src = HomeStyle.getBleDeviceBatteryIconSrc(homeStyleCode,venueSetting,0)
            contentObj.bleBattery.value = '--';
        } else {
            finish = false;
            if (!needForceOnline && (stateObj.offline === 1 || stateObj.offline === undefined)) {
                // TODO 显示该通道手机未接入网络
                mqttConnect = false;
                contentObj.connect.src = wifiDisconnectDarkIcon2x;
                contentObj.connect.value = '手机未接入网络';
                // contentObj.mobileBattery.src = (venueSetting && venueSetting.venueOpenState)?venuePhoneBatteryIcon:phoneDisconnectDarkIcon2x;
                contentObj.mobileBattery.src = HomeStyle.getMobileBatteryIconSrc(homeStyleCode,venueSetting,0)
                contentObj.mobileBattery.value = '--';
                // contentObj.bleBattery.src = (venueSetting && venueSetting.venueOpenState)?venueDeviceBatteryIcon:deviceDisconnectDarkIcon2x;  //TODO 替换icon
                contentObj.bleBattery.src = HomeStyle.getBleDeviceBatteryIconSrc(homeStyleCode,venueSetting,0)
                contentObj.bleBattery.value = '--';
            } else {
                mqttConnect = true;
                // 只要手机在线，手机电池电量就是有效的
                mobileBattery = stateObj.mobileBattery;
                if (mobileBattery) {
                    // if (mobileBattery <= 20) {
                    //     contentObj.mobileBattery.textColor = '#DC143C';
                    //     contentObj.mobileBattery.src = phoneBatteryLowDarkIcon2x;
                    // } else if (mobileBattery > 20 && mobileBattery <= 50) {
                    //     contentObj.mobileBattery.src = phoneBattery30DarkIcon2x;
                    // } else if (mobileBattery > 50 && mobileBattery <= 80) {
                    //     contentObj.mobileBattery.src = phoneBattery60DarkIcon2x;
                    // } else {
                    //     contentObj.mobileBattery.src = phoneBattery100DarkIcon2x;
                    // }
                    // if (venueSetting && venueSetting.venueOpenState){
                    //     contentObj.mobileBattery.src = venuePhoneBatteryIcon;
                    // }
                    if (mobileBattery <= 20) {
                        contentObj.mobileBattery.textColor = '#DC143C';
                    }
                    contentObj.mobileBattery.src = HomeStyle.getMobileBatteryIconSrc(homeStyleCode,venueSetting,1,mobileBattery)
                    contentObj.mobileBattery.value = mobileBattery && mobileBattery > 0? mobileBattery + '%' : '--';
                    console.log(`stateObj:`,stateObj)
                    if (stateObj.mobileChargeStatus === 2){
                        contentObj.mobileBattery.value = '充电中'
                        contentObj.mobileBattery.src = phoneChargeDarkIcon;
                    }
                }
                if (!needForceOnline && (stateObj.bleState === 0 || stateObj.bleState === undefined)) {
                    bleConnect = false;
                    contentObj.connect.src = bltDisconnectDarkIcon2x;
                    contentObj.connect.value = '心电仪未连接手机';
                    // contentObj.bleBattery.src = (venueSetting && venueSetting.venueOpenState)?venueDeviceBatteryIcon:deviceDisconnectDarkIcon2x;  //TODO 替换icon
                    contentObj.bleBattery.src = HomeStyle.getBleDeviceBatteryIconSrc(homeStyleCode,venueSetting,0)
                    contentObj.bleBattery.value = '--';
                } else {
                    bleConnect = true;
                    // 只要心电仪在线，心电仪电量就是有效的
                    bleBattery = stateObj.bleBattery;
                    if (bleBattery) {
                        // if (bleBattery <= 20) {
                        //     contentObj.bleBattery.textColor = '#DC143C';
                        //     contentObj.bleBattery.src = deviceBatteryLowDarkIcon2x;
                        // } else if (bleBattery > 20 && bleBattery <= 50) {
                        //     contentObj.bleBattery.src = deviceBattery30DarkIcon2x;
                        // } else if (bleBattery > 50 && bleBattery <= 80) {
                        //     contentObj.bleBattery.src = deviceBattery60DarkIcon2x;
                        // } else {
                        //     contentObj.bleBattery.src = deviceBattery100DarkIcon2x;
                        // }
                        // if (venueSetting && venueSetting.venueOpenState){
                        //     contentObj.bleBattery.src = venueDeviceBatteryIcon;
                        // }
                        if (bleBattery <= 20) {
                            contentObj.bleBattery.textColor = '#DC143C';
                        }
                        contentObj.bleBattery.src = HomeStyle.getBleDeviceBatteryIconSrc(homeStyleCode,venueSetting,1,bleBattery)
                        contentObj.bleBattery.value = bleBattery && bleBattery > 0? bleBattery + '%' : '--';
                    }
                }
            }
        }
    } else {
        contentObj.connect.src = channelDisconnectDarkIcon2x;    //TODO 替换icon
        contentObj.connect.value = '尚未连接';
        // contentObj.mobileBattery.src = (venueSetting && venueSetting.venueOpenState)?venuePhoneBatteryIcon:phoneDisconnectDarkIcon2x;
        contentObj.mobileBattery.src = HomeStyle.getMobileBatteryIconSrc(homeStyleCode,venueSetting,0)
        contentObj.mobileBattery.value = '--';
        // contentObj.bleBattery.src = (venueSetting && venueSetting.venueOpenState)?venueDeviceBatteryIcon:deviceDisconnectDarkIcon2x;  //TODO 替换icon
        contentObj.bleBattery.src = HomeStyle.getBleDeviceBatteryIconSrc(homeStyleCode,venueSetting,0)
        contentObj.bleBattery.value = '--';
    }
    contentObj.finish = finish;
    contentObj.bleConnect = bleConnect;
    contentObj.mqttConnect = mqttConnect;

    return JSON.parse(JSON.stringify(contentObj));
};

export default {update}
